var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{attrs:{"color":"white"}},[_c('v-data-table',{attrs:{"single-expand":"","show-expand":"","loading":_vm.loading,"headers":_vm.headers,"items":_vm.users,"hide-default-footer":"","items-per-page":_vm.resultsPerPage},scopedSlots:_vm._u([{key:"item.fullName",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/users/" + (item.id))}},[_vm._v(_vm._s(item.firstName)+" "+_vm._s(item.lastName))])]}},{key:"item.country",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("countryCodeToFullName")(item.country))+" ")]}},{key:"item.registerDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.registerDate))+" ")]}},{key:"item.lastLogin",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.lastLogin))+" ")]}},(this.$account.isFullAdmin || this.$account.permissions.canEditOutcome === true)?{key:"item.outcome",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.outcome)+" ")]}}:null,(this.$account.isFullAdmin || this.$account.permissions.canEditAgent === true)?{key:"item.agentId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getAgentNameById(item.agentId))+" ")]}}:{key:"item.agentId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getAgentNameById(item.agentId))+" ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"additional-row",attrs:{"colspan":headers.length}},[_c('div',{staticClass:"additional-row-head-wrapper"},[_c('table',{staticStyle:{"width":"100%"}},[_c('thead',[_c('tr',[_c('td',{staticClass:"font-weight-bold"},[_c('p',[_vm._v("ID")])]),_c('td',{staticClass:"font-weight-bold"},[_c('p',[_vm._v("Last Login")])]),_c('td',{staticClass:"font-weight-bold"},[_c('p',[_vm._v("Status")])]),_c('td',{staticClass:"font-weight-bold"},[_c('p',[_vm._v("Total Deposits")])]),_c('td',{staticClass:"font-weight-bold"},[_c('p',[_vm._v("Volume")])]),_c('td',{staticClass:"font-weight-bold"},[_c('p',[_vm._v("Bonuses")])]),_c('td',{staticClass:"font-weight-bold"},[_c('p',[_vm._v("Last Update")])])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v(_vm._s(item.id.slice(-5)))]),_c('td',[_vm._v("N/A")]),_c('td',[_vm._v(_vm._s(item.accountType))]),_c('td',[_vm._v(_vm._s(_vm._f("currency")(item.totalDeposits)))]),_c('td',[_vm._v(_vm._s(item.volume))]),_c('td',[_vm._v(_vm._s(_vm._f("currency")(item.balance)))]),_c('td',[_vm._v(_vm._s(_vm._f("date")(item.registerDate)))])])])])])])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('div',{staticClass:"pagination-wrapper"},[_c('button',{staticClass:"pagination_btn pagination-btn--previous",attrs:{"disabled":_vm.currentPage === 1},on:{"click":function($event){return _vm.prev()}}},[_c('v-icon',{staticClass:"pagination_btn-icon mr-2"},[_vm._v("mdi-chevron-left")]),_vm._v("Previous ")],1),_c('p',{staticClass:"pagination_total-page"},[_vm._v("Page "+_vm._s(_vm.currentPage)+" of "+_vm._s(_vm.totalPages)+" ("+_vm._s(_vm.totalUsers)+" results)")]),_c('button',{staticClass:"pagination_btn pagination-btn--next",attrs:{"disabled":_vm.currentPage === _vm.totalPages},on:{"click":function($event){return _vm.next()}}},[_vm._v(" Next"),_c('v-icon',{staticClass:"pagination_btn-icon ml-2"},[_vm._v("mdi-chevron-right")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }